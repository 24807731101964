/* CSS Document */

body {
	font-family: 'Helvetica Neue', Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f0f2f5;
	color: #333;
	line-height: 1.6;
}
.container {
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 20px;
}
header {
	background: url('../public/images/header.jpg') no-repeat center center/cover;
	color: #fff;
	padding: 275px 20px;
	text-align: center;
	position: relative;
	background-position: 5%;
}
header::before {
	content: '';
	position: absolute;
	top: 500;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 1;
}
header h1, header p {
	position: relative;
	z-index: 2;
}
header h1 {
	font-size: 2.5em;
	margin: 0;
}
header p {
	font-size: 1.2em;
}
section {
	background: #fff;
	margin: 20px 0;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
section h2 {
	color: #007bff;
}
.testimonial {
	font-style: italic;
	color: #555;
}
.cta {
	background-color: #007bff;
	color: #fff;
	padding: 10px 20px;
	text-align: center;
	display: inline-block;
	text-decoration: none;
	border-radius: 5px;
	margin-top: 10px;
}
.cta:hover {
	background-color: #0056b3;
}
.photo-gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr); /* Four columns */
	grid-auto-rows: minmax(100px, auto); /* Row height adjusts based on content */
	gap: 10px; /* Space between images */
	margin-bottom: 20px; /* Extra space below the grid */
}
.landscape {
	width: 100%; /* Full width of the grid column */
	height: auto; /* Height adjusts to maintain aspect ratio */
}
.portrait {
	grid-column: span 2; /* Each portrait image spans two columns */
	width: 100%;
	height: auto;
}
.photo-gallery img {
	width: 100%; /* Full width of the grid column */
	height: auto; /* Height adjusts to maintain aspect ratio */
	border-radius: 15px; /* Rounded corners */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
}
/* Specific positions */
.landscape:nth-child(1) { grid-area: 1 / 1 / 2 / 3; } /* First landscape spans two columns */
.landscape:nth-child(2) { grid-area: 1 / 3 / 2 / 5; } /* Second landscape spans two columns */
.landscape:nth-child(3) { grid-area: 2 / 1 / 3 / 3; } /* Third landscape spans two columns */
.landscape:nth-child(4) { grid-area: 2 / 3 / 3 / 5; } /* Fourth landscape spans two columns */
.portrait:nth-child(5) { grid-area: 3 / 1 / 4 / 3; } /* First portrait spans two columns */
.portrait:nth-child(6) { grid-area: 3 / 3 / 4 / 5; } /* Second portrait spans two columns */
footer {
	text-align: center;
	padding: 20px;
	background-color: #007bff;
	color: #fff;
}
footer a {
	color: #fff;
	text-decoration: none;
	margin: 0 10px;
}

